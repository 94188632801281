@use '@angular/material' as mat;
@import 'px-themes-colors-defaults';

@function color-light-theme($primary, $accent, $warn, $foreground, $background) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: map-merge($light-theme-foreground-defaults, $foreground),
    background: map-merge($light-theme-background-defaults, $background)
  );
}

@function color-dark-theme($primary, $accent, $warn, $foreground, $background) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: true,
    foreground: map-merge($dark-theme-foreground-defaults, $foreground),
    background: map-merge($dark-theme-background-defaults, $background)
  );
}

/// Use the mixin in the colors wrapper
@mixin color-scheme($colors, $logo, $class-name) {
  @include mat.all-component-themes($colors);
  @include ui-styles-all($colors);

  @if $logo == dark {
    app-gallery-brand .logo.light,
    px-ca-gallery-brand .logo.light {
      display: none !important;
    }
  }

  @if $logo == light {
    app-gallery-brand .logo.dark,
    px-ca-gallery-brand .logo.dark {
      display: none !important;
    }
  }

  section.gallery-cover {
    background-color: map-get(map-get($colors, background), cover);
  }

  // Set the body background color (fixes the issue with the white side bars on iPhone X in landscape):
  @at-root body#{$class-name}-bg {
    background-color: mat.m2-get-color-from-palette(map-get($colors, background), background);
  }
}

/// Use these helpers to set the foreground and background color based on the currently selected theming configuration.
@mixin color-helpers($colors) {
  /// Uses background color for text color
  .theme-text-color-inverted {
    color: mat.m2-get-color-from-palette(map-get($colors, background), background);
  }

  .theme-bg-color {
    background-color: mat.m2-get-color-from-palette(map-get($colors, background), background) !important;
  }

  .theme-bg-color-alpha-90 {
    background-color: change-color(mat.m2-get-color-from-palette(map-get($colors, background), background), $alpha: 0.9);
  }

  .theme-bg-color-alpha-80 {
    background-color: change-color(mat.m2-get-color-from-palette(map-get($colors, background), background), $alpha: 0.8);
  }

  .theme-bg-color-alpha-70 {
    background-color: change-color(mat.m2-get-color-from-palette(map-get($colors, background), background), $alpha: 0.7);
  }

  .theme-bg-color-alpha-60 {
    background-color: change-color(mat.m2-get-color-from-palette(map-get($colors, background), background), $alpha: 0.6);
  }

  .theme-bg-color-alpha-15 {
    background-color: change-color(mat.m2-get-color-from-palette(map-get($colors, background), background), $alpha: 0.15);
  }

  /// Uses text color for background color
  .theme-bg-color-inverted {
    background: mat.m2-get-color-from-palette(map-get($colors, foreground), text);
  }

  .theme-bg-color-cover {
    background: map-get(map-get($colors, background), cover);
  }
}
