@use 'sass:map';
@use '@angular/material' as mat;
@use 'libs/mds/styles' as mds;
@use 'libs/gds/styles' as gds;
@use 'libs/pui/styles' as pui;
@import 'helpers/px-themes-typography-helpers';

// ------------------------------------------------------------------------------
// Material Config
// ------------------------------------------------------------------------------

/* TODO: Move all parameters, except the font size, line height, font weight, into the wrapper classes.
         Reason: some typography levels, like $button, do not support changing the letter-spacing using the
         mat-typography-level function.
 */

// ------------------------------------------------------------------------------
// Wrapper Classes
// ------------------------------------------------------------------------------

.wrapper-typography-set-3 {
  @include pui.m-init-typography('gds', '&', map.get(gds.$theme-typography, 'base', 'bold'));
  @include mds.legacy-px-m-theme-create(
    (
      --px-ca-font-primary: (
        'Oswald',
        sans-serif,
      ),
      --px-ca-font-secondary: (
        'Roboto',
        sans-serif,
      ),
      --px-ca-input-font-weight: 300,
      --px-ca-button-font-weight: 300,
      --px-ca-dialog-title-font-weight: 300,
      --px-ca-typo-primary-letter-spacing: 0,
      --px-ca-typo-primary-text-transform: uppercase,
      --px-ca-typo-secondary-letter-spacing: 0.1em,
      --px-ca-typo-secondary-text-transform: uppercase,
      --px-ca-typo-body-letter-spacing: 0,
      --px-ca-typo-body-text-transform: none,
      --px-ca-typo-button-letter-spacing: 0.15em,
      --px-ca-typo-button-text-transform: uppercase,
      --px-ca-typo-input-letter-spacing: 0,
      --px-ca-typo-input-text-transform: none,
      --mds-input-font-family: var(--px-ca-font-secondary),
      --mds-input-font-weight: var(--px-ca-input-font-weight),
      --mds-input-primary-color: rgba(var(--px-ca-body-color-rgb), 0.8),
      --mds-input-secondary-color: rgba(var(--px-ca-body-color-rgb), 0.6),
      --mds-input-border-primary-color: var(--px-ca-body-color, rgba(0, 0, 0, 80%)),
      --mds-input-border-secondary-color: rgba(var(--px-ca-body-color-rgb), 12%),
      --mds-input-border-radius: 0,
      --mds-input-disabled-color: rgba(var(--px-ca-body-color-rgb), 0.38),
      --mds-input-background-color: var(--px-ca-input-background-color, var(--px-ca-body-background-color, #fff)),
      --mds-input-warn-color: var(--px-ca-warn, #f44336),
      --mds-input-height: 60px,
      --mds-input-checkbox-border-primary-color: var(--mds-input-border-primary-color),
      --mds-input-checkbox-border-secondary-color: var(--mds-input-border-secondary-color),
      --mds-input-checkbox-warn-color: #ffcc02,
      --mds-input-checkbox-border-radius: 0,
    ),
    null,
    false
  );

  $mat-config: mat.m2-define-typography-config(
    $font-family: "'Roboto', sans-serif",
    $headline-1: mat.m2-define-typography-level(70px, 70px, 500, $font-family: "'Oswald', sans-serif"),
    $headline-2: mat.m2-define-typography-level(56px, 56px, 500, $font-family: "'Oswald', sans-serif"),
    $headline-3: mat.m2-define-typography-level(34px, 34px, 400),
    $headline-4: mat.m2-define-typography-level(20px, 20px, 400),
    $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
    $headline-6: mat.m2-define-typography-level(20px, 32px, 500),
    $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400),
    $body-1: mat.m2-define-typography-level(15px, 24px, 400),
    $subtitle-2: mat.m2-define-typography-level(14px, 24px, 500),
    $body-2: mat.m2-define-typography-level(14px, 20px, 400),
    $caption: mat.m2-define-typography-level(12px, 20px, 400),
    $button: mat.m2-define-typography-level(14px, 14px, 300),
  );
  $config: (
    primary: typography-level(0, uppercase),
    secondary: typography-level(0.1em, uppercase),
    body: typography-level(0),
    button: typography-level(0.15em, uppercase),
    input: typography-level(0),
  );

  @include typography-set($mat-config, $config);
}

.wrapper-typography-set-5 {
  @include pui.m-init-typography('gds', '&', map.get(gds.$theme-typography, 'base', 'elegant'));
  @include mds.legacy-px-m-theme-create(
    (
      --px-ca-font-primary: (
        'SilkSerif-Light',
        'Times New Roman',
        serif,
      ),
      --px-ca-font-secondary: (
        'Mr-Eaves-Modern',
        sans-serif,
      ),
      --px-ca-input-font-weight: 300,
      --px-ca-button-font-weight: 300,
      --px-ca-dialog-title-font-weight: 300,
      --px-ca-typo-primary-letter-spacing: 0,
      --px-ca-typo-primary-text-transform: none,
      --px-ca-typo-secondary-letter-spacing: 0.15em,
      --px-ca-typo-secondary-text-transform: uppercase,
      --px-ca-typo-body-letter-spacing: 0.03em,
      --px-ca-typo-body-text-transform: none,
      --px-ca-typo-button-letter-spacing: 0.2em,
      --px-ca-typo-button-text-transform: uppercase,
      --px-ca-typo-input-letter-spacing: 0.07em,
      --px-ca-typo-input-text-transform: none,
      --mds-input-font-family: var(--px-ca-font-secondary),
      --mds-input-font-weight: var(--px-ca-input-font-weight),
      --mds-input-primary-color: rgba(var(--px-ca-body-color-rgb), 0.8),
      --mds-input-secondary-color: rgba(var(--px-ca-body-color-rgb), 0.6),
      --mds-input-border-primary-color: var(--px-ca-body-color, rgba(0, 0, 0, 80%)),
      --mds-input-border-secondary-color: rgba(var(--px-ca-body-color-rgb), 12%),
      --mds-input-border-radius: 0,
      --mds-input-disabled-color: rgba(var(--px-ca-body-color-rgb), 0.38),
      --mds-input-background-color: var(--px-ca-input-background-color, var(--px-ca-body-background-color, #fff)),
      --mds-input-warn-color: var(--px-ca-warn, #f44336),
      --mds-input-height: 60px,
      --mds-input-checkbox-border-primary-color: var(--mds-input-border-primary-color),
      --mds-input-checkbox-border-secondary-color: var(--mds-input-border-secondary-color),
      --mds-input-checkbox-warn-color: #ffcc02,
      --mds-input-checkbox-border-radius: 0,
    ),
    null,
    false
  );

  $mat-config: mat.m2-define-typography-config(
    $font-family: "'Mr-Eaves-Modern', sans-serif",
    $headline-1: mat.m2-define-typography-level(
        70px,
        70px,
        300,
        $font-family: "'SilkSerif-Light', 'Times New Roman', serif"
      ),
    $headline-2: mat.m2-define-typography-level(
        56px,
        56px,
        300,
        $font-family: "'SilkSerif-Light', 'Times New Roman', serif"
      ),
    $headline-3: mat.m2-define-typography-level(39px, 39px, 400),
    $headline-4: mat.m2-define-typography-level(23px, 23px, 400),
    $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
    $headline-6: mat.m2-define-typography-level(20px, 32px, 500),
    $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400),
    $body-1: mat.m2-define-typography-level(15px, 24px, 400),
    $subtitle-2: mat.m2-define-typography-level(16px, 24px, 500),
    $body-2: mat.m2-define-typography-level(16px, 20px, 400),
    $caption: mat.m2-define-typography-level(12px, 20px, 400),
    $button: mat.m2-define-typography-level(14px, 16px, 300),
  );
  $config: (
    primary: typography-level(0),
    secondary: typography-level(0.15em, uppercase),
    body: typography-level(0.03em),
    button: typography-level(0.2em, uppercase),
    input: typography-level(0.07em),
    amp-line-correction-y: 9%,
  );

  @include typography-set($mat-config, $config);
}

.wrapper-typography-set-7 {
  @include pui.m-init-typography('gds', '&', map.get(gds.$theme-typography, 'base', 'modern'));
  @include mds.legacy-px-m-theme-create(
    (
      --px-ca-font-primary: (
        'brandon-grotesque',
        sans-serif,
      ),
      --px-ca-font-secondary: (
        'Roboto Slab',
        serif,
      ),
      --px-ca-input-font-weight: 300,
      --px-ca-button-font-weight: 300,
      --px-ca-dialog-title-font-weight: 300,
      --px-ca-typo-primary-letter-spacing: 0.1em,
      --px-ca-typo-primary-text-transform: none,
      --px-ca-typo-secondary-letter-spacing: 0.03em,
      --px-ca-typo-secondary-text-transform: uppercase,
      --px-ca-typo-body-letter-spacing: 0.02em,
      --px-ca-typo-body-text-transform: none,
      --px-ca-typo-button-letter-spacing: 0.2em,
      --px-ca-typo-button-text-transform: none,
      --px-ca-typo-input-letter-spacing: 0,
      --px-ca-typo-input-text-transform: none,
      --mds-input-font-family: var(--px-ca-font-secondary),
      --mds-input-font-weight: var(--px-ca-input-font-weight),
      --mds-input-primary-color: rgba(var(--px-ca-body-color-rgb), 0.8),
      --mds-input-secondary-color: rgba(var(--px-ca-body-color-rgb), 0.6),
      --mds-input-border-primary-color: var(--px-ca-body-color, rgba(0, 0, 0, 80%)),
      --mds-input-border-secondary-color: rgba(var(--px-ca-body-color-rgb), 12%),
      --mds-input-border-radius: 0,
      --mds-input-disabled-color: rgba(var(--px-ca-body-color-rgb), 0.38),
      --mds-input-background-color: var(--px-ca-input-background-color, var(--px-ca-body-background-color, #fff)),
      --mds-input-warn-color: var(--px-ca-warn, #f44336),
      --mds-input-height: 60px,
      --mds-input-checkbox-border-primary-color: var(--mds-input-border-primary-color),
      --mds-input-checkbox-border-secondary-color: var(--mds-input-border-secondary-color),
      --mds-input-checkbox-warn-color: #ffcc02,
      --mds-input-checkbox-border-radius: 0,
    ),
    null,
    false
  );

  $mat-config: mat.m2-define-typography-config(
    $font-family: "'Roboto Slab', serif",
    $headline-1: mat.m2-define-typography-level(70px, 70px, 700, $font-family: "'brandon-grotesque', sans-serif"),
    $headline-2: mat.m2-define-typography-level(56px, 56px, 700, $font-family: "'brandon-grotesque', sans-serif"),
    $headline-3: mat.m2-define-typography-level(34px, 34px, 400),
    $headline-4: mat.m2-define-typography-level(20px, 20px, 400),
    $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
    $headline-6: mat.m2-define-typography-level(20px, 32px, 500),
    $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400),
    $body-1: mat.m2-define-typography-level(15px, 24px, 400),
    $subtitle-2: mat.m2-define-typography-level(14px, 24px, 500),
    $body-2: mat.m2-define-typography-level(14px, 20px, 400),
    $caption: mat.m2-define-typography-level(12px, 20px, 400),
    $button: mat.m2-define-typography-level(14px, 14px, 300),
  );
  $config: (
    primary: typography-level(0.1em, uppercase),
    secondary: typography-level(0.03em),
    body: typography-level(0.02em),
    button: typography-level(0.1em, none),
    input: typography-level(0),
    amp-line-correction-y: -6%,
  );

  @include typography-set($mat-config, $config);
}

.wrapper-typography-set-8 {
  @include pui.m-init-typography('gds', '&', map.get(gds.$theme-typography, 'base', 'simple'));
  @include mds.legacy-px-m-theme-create(
    (
      --px-ca-font-primary: (
        'acumin-pro',
        sans-serif,
      ),
      --px-ca-font-secondary: (
        'aktiv-grotesk',
        sans-serif,
      ),
      --px-ca-input-font-weight: 300,
      --px-ca-button-font-weight: 300,
      --px-ca-dialog-title-font-weight: 300,
      --px-ca-typo-primary-letter-spacing: 0,
      --px-ca-typo-primary-text-transform: none,
      --px-ca-typo-secondary-letter-spacing: 0.03em,
      --px-ca-typo-secondary-text-transform: none,
      --px-ca-typo-body-letter-spacing: 0,
      --px-ca-typo-body-text-transform: none,
      --px-ca-typo-button-letter-spacing: 0.1em,
      --px-ca-typo-button-text-transform: uppercase,
      --px-ca-typo-input-letter-spacing: 0,
      --px-ca-typo-input-text-transform: none,
      --mds-input-font-family: var(--px-ca-font-secondary),
      --mds-input-font-weight: var(--px-ca-input-font-weight),
      --mds-input-primary-color: rgba(var(--px-ca-body-color-rgb), 0.8),
      --mds-input-secondary-color: rgba(var(--px-ca-body-color-rgb), 0.6),
      --mds-input-border-primary-color: var(--px-ca-body-color, rgba(0, 0, 0, 80%)),
      --mds-input-border-secondary-color: rgba(var(--px-ca-body-color-rgb), 12%),
      --mds-input-border-radius: 0,
      --mds-input-disabled-color: rgba(var(--px-ca-body-color-rgb), 0.38),
      --mds-input-background-color: var(--px-ca-input-background-color, var(--px-ca-body-background-color, #fff)),
      --mds-input-warn-color: var(--px-ca-warn, #f44336),
      --mds-input-height: 60px,
      --mds-input-checkbox-border-primary-color: var(--mds-input-border-primary-color),
      --mds-input-checkbox-border-secondary-color: var(--mds-input-border-secondary-color),
      --mds-input-checkbox-warn-color: #ffcc02,
      --mds-input-checkbox-border-radius: 0,
    ),
    null,
    false
  );

  $mat-config: mat.m2-define-typography-config(
    $font-family: "'aktiv-grotesk', sans-serif",
    $headline-1: mat.m2-define-typography-level(70px, 70px, 400, $font-family: "'acumin-pro', sans-serif"),
    $headline-2: mat.m2-define-typography-level(56px, 56px, 400, $font-family: "'acumin-pro', sans-serif"),
    $headline-3: mat.m2-define-typography-level(34px, 34px, 400),
    $headline-4: mat.m2-define-typography-level(20px, 20px, 400),
    $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
    $headline-6: mat.m2-define-typography-level(20px, 32px, 500),
    $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400),
    $body-1: mat.m2-define-typography-level(15px, 24px, 400),
    $subtitle-2: mat.m2-define-typography-level(14px, 24px, 500),
    $body-2: mat.m2-define-typography-level(14px, 20px, 400),
    $caption: mat.m2-define-typography-level(12px, 20px, 400),
    $button: mat.m2-define-typography-level(14px, 14px, 300),
  );
  $config: (
    primary: typography-level(0),
    secondary: typography-level(0.03em),
    body: typography-level(0),
    button: typography-level(0.1em, uppercase),
    input: typography-level(0),
  );

  @include typography-set($mat-config, $config);
}

.wrapper-typography-set-10 {
  @include pui.m-init-typography('gds', '&', map.get(gds.$theme-typography, 'base', 'mono'));
  @include mds.legacy-px-m-theme-create(
    (
      --px-ca-font-primary: (
        'Roboto Mono',
        sans-serif,
      ),
      --px-ca-font-secondary: (
        'Roboto Mono',
        sans-serif,
      ),
      --px-ca-input-font-weight: 300,
      --px-ca-button-font-weight: 300,
      --px-ca-dialog-title-font-weight: 300,
      --px-ca-typo-primary-letter-spacing: 0,
      --px-ca-typo-primary-text-transform: none,
      --px-ca-typo-secondary-letter-spacing: 0.02em,
      --px-ca-typo-secondary-text-transform: none,
      --px-ca-typo-body-letter-spacing: 0,
      --px-ca-typo-body-text-transform: uppercase,
      --px-ca-typo-button-letter-spacing: 0,
      --px-ca-typo-button-text-transform: uppercase,
      --px-ca-typo-input-letter-spacing: 0,
      --px-ca-typo-input-text-transform: none,
      --mds-input-font-family: var(--px-ca-font-secondary),
      --mds-input-font-weight: var(--px-ca-input-font-weight),
      --mds-input-primary-color: rgba(var(--px-ca-body-color-rgb), 0.8),
      --mds-input-secondary-color: rgba(var(--px-ca-body-color-rgb), 0.6),
      --mds-input-border-primary-color: var(--px-ca-body-color, rgba(0, 0, 0, 80%)),
      --mds-input-border-secondary-color: rgba(var(--px-ca-body-color-rgb), 12%),
      --mds-input-border-radius: 0,
      --mds-input-disabled-color: rgba(var(--px-ca-body-color-rgb), 0.38),
      --mds-input-background-color: var(--px-ca-input-background-color, var(--px-ca-body-background-color, #fff)),
      --mds-input-warn-color: var(--px-ca-warn, #f44336),
      --mds-input-height: 60px,
      --mds-input-checkbox-border-primary-color: var(--mds-input-border-primary-color),
      --mds-input-checkbox-border-secondary-color: var(--mds-input-border-secondary-color),
      --mds-input-checkbox-warn-color: #ffcc02,
      --mds-input-checkbox-border-radius: 0,
    ),
    null,
    false
  );

  $mat-config: mat.m2-define-typography-config(
    $font-family: "'Roboto Mono', monospace",
    $headline-1: mat.m2-define-typography-level(70px, 70px, 300, $font-family: "'Roboto Mono', monospace"),
    $headline-2: mat.m2-define-typography-level(56px, 56px, 300, $font-family: "'Roboto Mono', monospace"),
    $headline-3: mat.m2-define-typography-level(34px, 34px, 400),
    $headline-4: mat.m2-define-typography-level(20px, 20px, 400),
    $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
    $headline-6: mat.m2-define-typography-level(20px, 32px, 500),
    $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400),
    $body-1: mat.m2-define-typography-level(15px, 24px, 400),
    $subtitle-2: mat.m2-define-typography-level(14px, 24px, 500),
    $body-2: mat.m2-define-typography-level(14px, 20px, 400),
    $caption: mat.m2-define-typography-level(12px, 20px, 400),
    $button: mat.m2-define-typography-level(14px, 14px, 300),
  );
  $config: (
    primary: typography-level(0),
    secondary: typography-level(0.02em, uppercase),
    body: typography-level(0),
    button: typography-level(0, none),
    input: typography-level(0),
    amp-line-correction-y: 5%,
  );

  @include typography-set($mat-config, $config);
}

.wrapper-typography-set-12 {
  @include pui.m-init-typography('gds', '&', map.get(gds.$theme-typography, 'base', 'classic'));
  @include mds.legacy-px-m-theme-create(
    (
      --px-ca-font-primary: (
        'Adobe-Garamond-Pro',
        serif,
      ),
      --px-ca-font-secondary: (
        'canada-type-gibson',
        sans-serif,
      ),
      --px-ca-input-font-weight: 300,
      --px-ca-button-font-weight: 300,
      --px-ca-dialog-title-font-weight: 300,
      --px-ca-typo-primary-letter-spacing: 0.18em,
      --px-ca-typo-primary-text-transform: uppercase,
      --px-ca-typo-secondary-letter-spacing: 0.1em,
      --px-ca-typo-secondary-text-transform: uppercase,
      --px-ca-typo-body-letter-spacing: 0.03em,
      --px-ca-typo-body-text-transform: none,
      --px-ca-typo-button-letter-spacing: 0.1em,
      --px-ca-typo-button-text-transform: uppercase,
      --px-ca-typo-input-letter-spacing: 0.1em,
      --px-ca-typo-input-text-transform: none,
      --mds-input-font-family: var(--px-ca-font-secondary),
      --mds-input-font-weight: var(--px-ca-input-font-weight),
      --mds-input-primary-color: rgba(var(--px-ca-body-color-rgb), 0.8),
      --mds-input-secondary-color: rgba(var(--px-ca-body-color-rgb), 0.6),
      --mds-input-border-primary-color: var(--px-ca-body-color, rgba(0, 0, 0, 80%)),
      --mds-input-border-secondary-color: rgba(var(--px-ca-body-color-rgb), 12%),
      --mds-input-border-radius: 0,
      --mds-input-disabled-color: rgba(var(--px-ca-body-color-rgb), 0.38),
      --mds-input-background-color: var(--px-ca-input-background-color, var(--px-ca-body-background-color, #fff)),
      --mds-input-warn-color: var(--px-ca-warn, #f44336),
      --mds-input-height: 60px,
      --mds-input-checkbox-border-primary-color: var(--mds-input-border-primary-color),
      --mds-input-checkbox-border-secondary-color: var(--mds-input-border-secondary-color),
      --mds-input-checkbox-warn-color: #ffcc02,
      --mds-input-checkbox-border-radius: 0,
    ),
    null,
    false
  );

  // TODO: Add Avenir font-face CSS (currently the local font is being used).

  $mat-config: mat.m2-define-typography-config(
    $font-family: "'canada-type-gibson', sans-serif",
    $headline-1: mat.m2-define-typography-level(70px, 70px, 400, $font-family: "'Adobe-Garamond-Pro', serif"),
    $headline-2: mat.m2-define-typography-level(56px, 56px, 400, $font-family: "'Adobe-Garamond-Pro', serif"),
    $headline-3: mat.m2-define-typography-level(34px, 34px, 400),
    $headline-4: mat.m2-define-typography-level(20px, 20px, 400),
    $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
    $headline-6: mat.m2-define-typography-level(20px, 32px, 500),
    $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400),
    $body-1: mat.m2-define-typography-level(15px, 24px, 400),
    $subtitle-2: mat.m2-define-typography-level(14px, 24px, 500),
    $body-2: mat.m2-define-typography-level(14px, 20px, 400),
    $caption: mat.m2-define-typography-level(12px, 20px, 400),
    $button: mat.m2-define-typography-level(14px, 14px, 300),
  );
  $config: (
    primary: typography-level(0.18em, uppercase),
    secondary: typography-level(0.1em, uppercase),
    body: typography-level(0.03em),
    button: typography-level(0.1em, uppercase),
    input: typography-level(0.1em),
    amp-line-correction-y: -15%,
  );

  @include typography-set($mat-config, $config);
}

.wrapper-typography-set-13 {
  @include pui.m-init-typography('gds', '&', map.get(gds.$theme-typography, 'base', 'timeless'));
  @include mds.legacy-px-m-theme-create(
    (
      --px-ca-font-primary: (
        'Lora',
        serif,
      ),
      --px-ca-font-secondary: (
        'Roboto',
        sans-serif,
      ),
      --px-ca-input-font-weight: 300,
      --px-ca-button-font-weight: 300,
      --px-ca-dialog-title-font-weight: 300,
      --px-ca-typo-primary-letter-spacing: 0.12em,
      --px-ca-typo-primary-text-transform: uppercase,
      --px-ca-typo-secondary-letter-spacing: 0.1em,
      --px-ca-typo-secondary-text-transform: uppercase,
      --px-ca-typo-body-letter-spacing: 0,
      --px-ca-typo-body-text-transform: none,
      --px-ca-typo-button-letter-spacing: 0.15em,
      --px-ca-typo-button-text-transform: uppercase,
      --px-ca-typo-input-letter-spacing: 0,
      --px-ca-typo-input-text-transform: none,
      --mds-input-font-family: var(--px-ca-font-secondary),
      --mds-input-font-weight: var(--px-ca-input-font-weight),
      --mds-input-primary-color: rgba(var(--px-ca-body-color-rgb), 0.8),
      --mds-input-secondary-color: rgba(var(--px-ca-body-color-rgb), 0.6),
      --mds-input-border-primary-color: var(--px-ca-body-color, rgba(0, 0, 0, 80%)),
      --mds-input-border-secondary-color: rgba(var(--px-ca-body-color-rgb), 12%),
      --mds-input-border-radius: 0,
      --mds-input-disabled-color: rgba(var(--px-ca-body-color-rgb), 0.38),
      --mds-input-background-color: var(--px-ca-input-background-color, var(--px-ca-body-background-color, #fff)),
      --mds-input-warn-color: var(--px-ca-warn, #f44336),
      --mds-input-height: 60px,
      --mds-input-checkbox-border-primary-color: var(--mds-input-border-primary-color),
      --mds-input-checkbox-border-secondary-color: var(--mds-input-border-secondary-color),
      --mds-input-checkbox-warn-color: #ffcc02,
      --mds-input-checkbox-border-radius: 0,
    ),
    null,
    false
  );

  $mat-config: mat.m2-define-typography-config(
    $font-family: "'Roboto', sans-serif",
    $headline-1: mat.m2-define-typography-level(70px, 70px, 400, $font-family: "'Lora', serif"),
    $headline-2: mat.m2-define-typography-level(56px, 56px, 400, $font-family: "'Lora', serif"),
    $headline-3: mat.m2-define-typography-level(34px, 34px, 400),
    $headline-4: mat.m2-define-typography-level(20px, 20px, 400),
    $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
    $headline-6: mat.m2-define-typography-level(20px, 32px, 500),
    $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400),
    $body-1: mat.m2-define-typography-level(15px, 24px, 400),
    $subtitle-2: mat.m2-define-typography-level(14px, 24px, 500),
    $body-2: mat.m2-define-typography-level(14px, 20px, 400),
    $caption: mat.m2-define-typography-level(12px, 20px, 400),
    $button: mat.m2-define-typography-level(14px, 14px, 300),
  );
  $config: (
    primary: typography-level(0.12em, uppercase),
    secondary: typography-level(0.1em, uppercase),
    body: typography-level(0),
    button: typography-level(0.15em, uppercase),
    input: typography-level(0),
    amp-line-correction-y: -4%,
  );

  @include typography-set($mat-config, $config);
}

.wrapper-typography-set-unsupported-browser {
  @include pui.m-init-typography('gds', '&', map.get(gds.$theme-typography, 'base', 'simple'));
  @include mds.legacy-px-m-theme-create(
    (
      --px-ca-font-primary: (
        'acumin-pro',
        sans-serif,
      ),
      --px-ca-font-secondary: (
        'aktiv-grotesk',
        sans-serif,
      ),
      --px-ca-input-font-weight: 300,
      --px-ca-button-font-weight: 300,
      --px-ca-dialog-title-font-weight: 300,
      --px-ca-typo-primary-letter-spacing: 0,
      --px-ca-typo-primary-text-transform: none,
      --px-ca-typo-secondary-letter-spacing: 0.03em,
      --px-ca-typo-secondary-text-transform: none,
      --px-ca-typo-body-letter-spacing: 0,
      --px-ca-typo-body-text-transform: none,
      --px-ca-typo-button-letter-spacing: 0.1em,
      --px-ca-typo-button-text-transform: uppercase,
      --px-ca-typo-input-letter-spacing: 0,
      --px-ca-typo-input-text-transform: none,
      --mds-input-font-family: var(--px-ca-font-secondary),
      --mds-input-font-weight: var(--px-ca-input-font-weight),
      --mds-input-primary-color: rgba(var(--px-ca-body-color-rgb), 0.8),
      --mds-input-secondary-color: rgba(var(--px-ca-body-color-rgb), 0.6),
      --mds-input-border-primary-color: var(--px-ca-body-color, rgba(0, 0, 0, 80%)),
      --mds-input-border-secondary-color: rgba(var(--px-ca-body-color-rgb), 12%),
      --mds-input-border-radius: 0,
      --mds-input-disabled-color: rgba(var(--px-ca-body-color-rgb), 0.38),
      --mds-input-background-color: var(--px-ca-input-background-color, var(--px-ca-body-background-color, #fff)),
      --mds-input-warn-color: var(--px-ca-warn, #f44336),
      --mds-input-height: 60px,
      --mds-input-checkbox-border-primary-color: var(--mds-input-border-primary-color),
      --mds-input-checkbox-border-secondary-color: var(--mds-input-border-secondary-color),
      --mds-input-checkbox-warn-color: #ffcc02,
      --mds-input-checkbox-border-radius: 0,
    ),
    null,
    false
  );

  $mat-config: mat.m2-define-typography-config(
    $font-family: "'aktiv-grotesk', sans-serif",
    $headline-1: mat.m2-define-typography-level(70px, 70px, 400),
    $headline-2: mat.m2-define-typography-level(56px, 56px, 400),
    $headline-3: mat.m2-define-typography-level(34px, 34px, 400),
    $headline-4: mat.m2-define-typography-level(20px, 20px, 400),
    $headline-5: mat.m2-define-typography-level(56px, 66px, 300, $font-family: "'acumin-pro', sans-serif"),
    $headline-6: mat.m2-define-typography-level(16px, 24px, 400),
    $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400),
    $body-1: mat.m2-define-typography-level(15px, 24px, 400),
    $subtitle-2: mat.m2-define-typography-level(14px, 24px, 500),
    $body-2: mat.m2-define-typography-level(14px, 16px, 400),
    $caption: mat.m2-define-typography-level(12px, 20px, 400),
    $button: mat.m2-define-typography-level(14px, 14px, 300),
  );
  $config: (
    primary: typography-level(0),
    secondary: typography-level(0.03em),
    body: typography-level(0),
    button: typography-level(0.1em, uppercase),
    input: typography-level(0),
  );

  @include typography-set($mat-config, $config);
}

.wrapper-typography-set-3,
.wrapper-typography-set-5,
.wrapper-typography-set-7,
.wrapper-typography-set-8,
.wrapper-typography-set-10,
.wrapper-typography-set-12,
.wrapper-typography-set-13,
.wrapper-typography-set-unsupported-browser {
  --mds-comp-textarea-font-family: var(--mds-input-font-family);
  --mds-comp-textarea-font-weight: var(--mds-input-font-weight);
  --mds-comp-textarea-primary-color: var(--mds-input-primary-color);
  --mds-comp-textarea-secondary-color: var(--mds-input-secondary-color);
  --mds-comp-textarea-border-primary-color: var(--mds-input-border-primary-color);
  --mds-comp-textarea-border-secondary-color: var(--mds-input-border-secondary-color);
  --mds-comp-textarea-border-radius: var(--mds-input-border-radius);
  --mds-comp-textarea-disabled-color: var(--mds-input-disabled-color);
  --mds-comp-textarea-background-color: var(--mds-input-background-color);
}
