@use 'sass:map';
@use 'sass:string';
@use 'sass:color';
@use 'sass:meta';
@use 'tools';
@use './../tokens/theme-configs';

@mixin m-init-theme($prefix: 'pui', $root: ':root', $config: ()) {
  @if meta.type-of($prefix) == list {
    @each $theme in $prefix {
      @include m-init-theme($theme, $root, $config);
    }
  } @else {
    @include m-create-theme-vars($prefix, tools.flat-map($config), $root);
  }
}

@mixin m-init-theme-legacy($name, $root: ':root', $prefix: 'pui', $custom-config: ()) {
  $config: map.get(theme-configs.$theme-configs, $name);

  @if $custom-config != () {
    $config: map.deep-merge($config, $custom-config);
  }

  @include m-create-theme-vars($prefix, tools.flat-map($config), $root);
}

@mixin m-create-theme-vars($prefix: 'pui', $vars, $root: ':root') {
  #{$root} {
    @each $key, $value in $vars {
      @if $value != () {
        --#{$prefix}-#{$key}: #{$value};

        @if meta.type-of($value) == color and meta.type-of($key) == string {
          --#{$prefix}-#{$key}-rgb: #{color.red($value)}, #{color.green($value)}, #{color.blue($value)};
        }
      }
    }
  }
}

@mixin m-set-comp-varset($prefix: 'pui', $comp-name, $vars: ()) {
  $varset: ();

  @each $prop, $value in tools.flat-map($vars) {
    --#{$prefix}-comp-#{$comp-name}-#{$prop}: #{$value};
  }
}

@mixin m-define-comp-varset($prefix: 'pui', $comp-name, $vars: (), $props: ()) {
  $varset: ();

  @each $prop, $value in $vars {
    $clean-prop: $prop;

    @if string.index($prop, '_') == 1 {
      $clean-prop: string.slice($prop, 2);
    }

    @if map.get($props, $prop) {
      $varset: map.set($varset, $prop, var(--#{$prefix}-comp-#{$comp-name}-#{$clean-prop}, map.get($props, $prop)));
    } @else {
      $varset: map.set($varset, $prop, var(--#{$prefix}-comp-#{$comp-name}-#{$clean-prop}));
    }
  }

  @each $prop, $var in $varset {
    @if string.index($prop, '_') == 1 {
      $prop: string.slice($prop, 2);
    }

    --#{$prop}: #{$var};
  }

  @each $prop, $var in $varset {
    @if string.index($prop, '_') != 1 {
      #{$prop}: var(--#{$prop});
    }
  }
}
