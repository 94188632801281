@import '../icons/font/icons.css';

.icon {
  font-weight: normal !important;
  font-family: 'icons', serif !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
