@use '@angular/material' as mat;

$_typography-levels: (
  display-4: primary,
  display-3: primary,
  display-2: secondary,
  display-1: secondary,
  headline: body,
  title: body,
  subheading-2: body,
  subheading-1: body,
  body-2: body,
  body-1: body,
  caption: body,
  button: button,
  input: input,
);
$_default-typography-level: (
  letter-spacing: normal,
  text-transform: none,
);
$_default-typography-levels: (
  primary: $_default-typography-level,
  secondary: $_default-typography-level,
  body: $_default-typography-level,
  button: $_default-typography-level,
  input: $_default-typography-level,
  amp-line-correction-y: 0,
);

@function typography-level($letter-spacing: normal, $text-transform: none) {
  @return (letter-spacing: $letter-spacing, text-transform: $text-transform);
}

@mixin typography-set($mat-config, $config) {
  $config: _default_config($config);
  $mat-config: _apply-letter-spacing($mat-config, $config);
  @include mat.all-component-typographies($mat-config);

  input {
    letter-spacing: _letter-spacing-for-level(input, $config) !important;
    text-transform: _text-transform-for-level(input, $config) !important;
  }

  .mat-headline-1,
  .mat-headline-2 {
    letter-spacing: _letter-spacing-for-level(primary, $config) !important;
    text-transform: _text-transform-for-level(primary, $config) !important;
  }

  .mat-headline-3,
  .mat-headline-4 {
    letter-spacing: _letter-spacing-for-level(secondary, $config) !important;
    text-transform: _text-transform-for-level(secondary, $config) !important;
  }

  // Set the CSS variable to use in the .amp-line class:
  --amp-line-correction-y: #{map-get($config, amp-line-correction-y)};
}

@function _default_config($config) {
  @return map-merge($_default-typography-levels, $config);
}

@function _letter-spacing-for-mat-level($mat-level, $mat-config, $config) {
  @if not map-has-key($mat-config, $mat-level) {
    @return (letter-spacing: inherit);
  }

  @return map-merge(
    map-get($mat-config, $mat-level),
    (
      letter-spacing: map-get(map-get($config, map-get($_typography-levels, $mat-level)), letter-spacing),
    )
  );
}

@function _letter-spacing-for-level($level, $config) {
  @return map-get(map-get($config, $level), letter-spacing);
}

@function _text-transform-for-level($level, $config) {
  @return map-get(map-get($config, $level), text-transform);
}

@function _apply-letter-spacing($mat-config, $config) {
  $new-mat-config: $mat-config;

  @each $mat-level, $level in $_typography-levels {
    $new-mat-config: map-merge(
      $new-mat-config,
      (
        $mat-level: _letter-spacing-for-mat-level($mat-level, $mat-config, $config),
      )
    );
  }

  @return $new-mat-config;
}
