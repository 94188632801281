@use 'sass:math';
@import 'apps/psf-themes/src/assets/scss/breakpoints';

// ------------------------------------------------------------------------------
// Mixins:
// ------------------------------------------------------------------------------

@mixin spacing($spacing) {
  &.image-grid-container {
    // Negative top margin to avoid the extra space caused by the grid spacing:
    margin-top: -(math.div(map-get($spacing, spacing), 2));
    margin-bottom: (math.div(map-get($spacing, spacing), 2));
    padding-right: map-get($spacing, margin) - math.div(map-get($spacing, spacing), 2);
    padding-left: map-get($spacing, margin) - math.div(map-get($spacing, spacing), 2);

    .section {
      .section-title-wrapper {
        // Compensate for the negative top margin of the image-grid-container:
        &:first-of-type {
          margin-top: math.div(map-get($spacing, spacing), 2);
        }
      }
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: (math.div(map-get($spacing, mobile-spacing), 2));

      @if map-get($spacing, is-mobile-full-width) {
        // No side margins on mobile:
        padding-left: 0;
        padding-right: 0;

        // No side margins: Shift to accommodate the extra padding on .image-wrapper:
        .row.no-gutters {
          margin-right: -(math.div(map-get($spacing, mobile-spacing), 2));
          margin-left: -(math.div(map-get($spacing, mobile-spacing), 2));
        }
      } @else {
        padding-left: math.div(map-get($spacing, mobile-spacing), 2);
        padding-right: math.div(map-get($spacing, mobile-spacing), 2);
      }
    }
  }

  .image-wrapper {
    padding: math.div(map-get($spacing, spacing), 2);

    @include media-breakpoint-down(sm) {
      padding: math.div(map-get($spacing, mobile-spacing), 2);
    }
  }
}

// ------------------------------------------------------------------------------
// Image Grid Spacing Presets:
// ------------------------------------------------------------------------------

$spacing-thin: (
  margin: 60px,
  spacing: 6px,
  mobile-margin: 0,
  mobile-spacing: 4px,
  is-mobile-full-width: true,
);

.wrapper-spacing-thin {
  @include spacing($spacing-thin);
}

// ------------------------------------------------------------------------------

$spacing-wide: (
  margin: 60px,
  spacing: 16px,
  mobile-margin: 0,
  mobile-spacing: 14px,
  is-mobile-full-width: false,
);

.wrapper-spacing-wide {
  @include spacing($spacing-wide);
}

// ------------------------------------------------------------------------------

:root {
  --thinMargin: #{map-get($spacing-thin, margin)};
  --thinSpacing: #{map-get($spacing-thin, spacing)};
  --thinMobileMargin: #{map-get($spacing-thin, mobile-margin)};
  --thinMobileSpacing: #{map-get($spacing-thin, mobile-spacing)};
  --thinIsMobileFullWidth: #{map-get($spacing-thin, is-mobile-full-width)};
  --wideMargin: #{map-get($spacing-wide, margin)};
  --wideSpacing: #{map-get($spacing-wide, spacing)};
  --wideMobileMargin: #{map-get($spacing-wide, mobile-margin)};
  --wideMobileSpacing: #{map-get($spacing-wide, mobile-spacing)};
  --wideIsMobileFullWidth: #{map-get($spacing-wide, is-mobile-full-width)};
}
