@use 'sass:meta';
@use 'sass:map';
@use 'sass:string';
@use 'var';

@function flatten-map($map, $reference: null, $result: null) {
  $result: if($result == null, (), $result);

  @each $key, $value in $map {
    $key: if($reference == null, #{$key}, #{$reference}-#{$key});

    @if meta.type-of($value) == map {
      $result: flatten-map($value, $key, $result);
    } @else {
      $result: map.set($result, $key, $value);
    }
  }

  @return $result;
}

@mixin m-override-sys-color-vars($theme-config, $color-scheme: null, $root: ':root') {
  $colors: if(
    $color-scheme,
    map.get($theme-config, sys, color-scheme, $color-scheme),
    map.get($theme-config, sys, color)
  );

  #{$root} {
    @each $key, $value in $colors {
      $prefix: '--mds-sys-color';
      $scheme-var-name: if(
        $color-scheme,
        --mds-sys-color-scheme-#{$color-scheme}-#{string.unquote($key)},
        --mds-sys-color-#{string.unquote($key)}
      );

      @include var.m-set-custom-prop(#{$prefix}-#{$key}, var(#{$scheme-var-name}));
      @include var.m-set-custom-prop(#{$prefix}-#{$key}-rgb, var(#{$scheme-var-name}-rgb));
    }
  }
}

@mixin m-create-theme-vars($map, $prop-name, $root: ':root', $prefix: null) {
  #{$root} {
    @each $key, $value in flatten-map($map) {
      $current-prefix: if($prefix == null, #{$prop-name}-#{$key}, #{$prefix}-#{$prop-name}-#{$key});

      @if meta.type-of($value) == color and meta.type-of($key) == string {
        $rgb: red($value), green($value), blue($value);

        @include var.m-set-custom-prop(#{$current-prefix}-rgb, $rgb);
      }

      @include var.m-set-custom-prop(#{$current-prefix}, $value);
    }
  }
}

@mixin m-define-theme-vars($theme-config, $root: ':root', $prefix: null) {
  @each $key, $value in $theme-config {
    @include m-create-theme-vars($value, $key, $root, $prefix);
  }

  // uncomment code below to enable system color scheme support when they are ready
  //@media (prefers-color-scheme: light) {
  //  @include m-override-sys-color-vars($theme-config);
  //}
  //@media (prefers-color-scheme: dark) {
  //  @include m-override-sys-color-vars($theme-config, dark);
  //}
  //@include m-override-sys-color-vars($theme-config, null, '.mds-light-color-scheme');
  //@include m-override-sys-color-vars($theme-config, dark, '.mds-dark-color-scheme');
}
