@mixin m-spinner-base {
  $color: var(--px-ca-spinner-color, #eeeeee);

  display: block;
  width: 24px;
  height: 24px;
  border-width: 2px;
  border-style: solid;
  border-color: $color transparent $color $color;
  border-radius: 50%;
  animation: spinner-rotation 1.2s linear infinite;
}

@keyframes spinner-rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
