// Imports
@use 'libs/pui/styles' as pui;
@use '@angular/material' as mat;
@use 'libs/mds/styles' as mds;
@use 'libs/gds/styles' as gds;
@use 'sass:map';

// Reset
@include pui.m-prepare;

// Bootstrap
// ------------------------------------------------------------------------------

$dark-focused: mat.m2-get-color-from-palette(mat.$m2-gray-palette, 'focused-button');
$dark-dividers: mat.m2-get-color-from-palette(mat.$m2-gray-palette, 'divider');
$dark-disabled-text: mat.m2-get-color-from-palette(mat.$m2-gray-palette, 'disabled');
$dark-secondary-text: mat.m2-get-color-from-palette(mat.$m2-gray-palette, 'secondary-text');
$light-focused: mat.m2-get-color-from-palette(mat.$m2-gray-palette, 'focused-button');
$light-dividers: mat.m2-get-color-from-palette(mat.$m2-gray-palette, 'divider');
$light-disabled-text: mat.m2-get-color-from-palette(mat.$m2-gray-palette, 'disabled');
$light-secondary: mat.m2-get-color-from-palette(mat.$m2-gray-palette, 'secondary-text');
$light-secondary-text: mat.m2-get-color-from-palette(mat.$m2-gray-palette, 'secondary-text');

// Genesis, Chapter 1: The Breakpoints

@import 'photoswipe/dist/photoswipe';
@import 'breakpoints';
@import 'icons';
@import 'bootstrap-grid-only-css/scss/functions';
@import 'bootstrap-grid-only-css/scss/variables';
@import 'bootstrap-grid-only-css/scss/bootstrap-grid';

// ------------------------------------------------------------------------------
// Angular Material
// ------------------------------------------------------------------------------

@include mat.all-component-typographies;
@include mat.core;

// @include mat.legacy-core;

// ------------------------------------------------------------------------------
// Gallery Theme Styles
// ------------------------------------------------------------------------------

@import 'theming/px-themes-ui-styles';
@import 'theming/px-themes-colors';
@import 'theming/px-themes-typography';
@import 'theming/px-themes-image-grid-spacing';

// Sticky header requires all parent elements to have the default `overflow` property ('visible').
// `overflow: hidden` should not be used here:
// `overflow-anchor: none;` added to not change scroll position automatically when switching between section using footer links.
:root {
  --px-dot-loader-color: gray;
  --foreground-light: #f3f3f3;
  --foreground-dark: #424242;
  --px-notification-issue-banner-color-bg: var(--foreground-light);
  --mds-comp-tooltip-border-radius: 0;
}

html {
  position: relative;
  top: 0;
}

body {
  margin: 0;

  // overflow-x: hidden;
  // touch-action: manipulation;
  // overscroll-behavior: none;
  // overflow-anchor: none;

  @include pui.m-use-typeset('gds', 'body');

  &.colors-light-bg {
    --foreground-color: var(--foreground-light);
    --mds-comp-banner-color: var(--px-ca-body-color);
    --mds-comp-banner-background-color: var(--px-notification-issue-banner-color-bg);
  }

  &.colors-dark-bg {
    --foreground-color: var(--foreground-dark);
    --px-notification-issue-banner-color-bg: var(--foreground-dark);
    --mds-comp-banner-color: var(--px-ca-body-color);
    --mds-comp-banner-background-color: var(--px-notification-issue-banner-color-bg);
  }
}

.mat-app-background {
  min-height: 100%;
}

// Preview mode for mobile devices should have no scroll to always have proper viewport size
@include media-breakpoint-down(xs) {
  html.is-preview-mode,
  html.is-preview-mode body {
    -ms-overflow-style: none; /* Internet Explorer 10+ and Edge */
    scrollbar-width: none; /* Firefox */
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
}

@include pui.m-init-theme(
  ('pui', 'mds', 'gds'),
  '.colors-light-bg',
  map.deep-merge(map.get(mds.$theme-configs, 'ca-light'), map.get(gds.$theme-configs, 'light'))
);
@include pui.m-init-theme(
  ('pui', 'mds', 'gds'),
  '.colors-dark-bg',
  map.deep-merge(map.get(mds.$theme-configs, 'ca-dark'), map.get(gds.$theme-configs, 'dark'))
);
@include pui.m-init-theme(
  ('pui', 'mds', 'gds'),
  '.colors-soft-bg',
  map.deep-merge(map.get(mds.$theme-configs, 'ca-soft'), map.get(gds.$theme-configs, 'soft'))
);
@include pui.m-init-theme(
  ('pui', 'mds', 'gds'),
  '.colors-champagne-bg',
  map.deep-merge(map.get(mds.$theme-configs, 'ca-champagne'), map.get(gds.$theme-configs, 'champagne'))
);
@include pui.m-init-theme(
  ('pui', 'mds', 'gds'),
  '.colors-ocean-bg',
  map.deep-merge(map.get(mds.$theme-configs, 'ca-ocean'), map.get(gds.$theme-configs, 'ocean'))
);

body:not(:has(.gallery)) {
  overflow: hidden;
}
