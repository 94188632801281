@use '@angular/material' as mat;
@import 'node_modules/bootstrap-grid-only-css/scss/functions';
@import 'node_modules/bootstrap-grid-only-css/scss/variables';
@import 'node_modules/bootstrap-grid-only-css/scss/mixins/breakpoints';
@import 'helpers/px-themes-color-helpers';

@mixin ui-style-artsy($colors) {
  &.ui-style-artsy {
    $dialog-padding: 36px;

    @include color-helpers($colors);

    a,
    a:visited {
      color: mat.m2-get-color-from-palette(map-get($colors, foreground), text);
    }

    // Default button styling:
    button:not([mat-icon-button], .c-button, .button) {
      padding: 0 28px;
      line-height: 40px;
      text-transform: uppercase;

      @include media-breakpoint-down(sm) {
        padding: 0 22px;
      }
    }
  }
}

/// The mixin includes all UI styles. To be used in the colors wrapper.
@mixin ui-styles-all($colors) {
  @include ui-style-artsy($colors);
}
