@use './utils/media';

@mixin m-prepare() {
  @include m-reset-css;
  @include m-imports-prepare;
  @include m-reset;
}

@mixin m-imports-prepare() {
  @import '@angular/cdk/overlay-prebuilt.css';
  @import 'overlayscrollbars/styles/overlayscrollbars.css';
}

@mixin m-reset() {
  * {
    outline: none;
  }

  html,
  body {
    height: 100dvh;
    margin: 0;
    padding: 0;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    color: var(--pui-sys-color-on-background);
    text-shadow: rgba(0, 0, 0, 1%) 0 0 1px;
    background-color: var(--pui-sys-color-background);
    touch-action: manipulation;
    text-size-adjust: 100%;
    font-variant-ligatures: none;
    text-rendering: optimizelegibility;
    overscroll-behavior: none;
    overflow-anchor: none;
    -webkit-tap-highlight-color: transparent;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }
}

@mixin m-reset-css() {
  *,
  ::after,
  ::before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }

  html {
    box-sizing: border-box;
    font-family: sans-serif;
    line-height: 1.15;
  }

  main {
    display: block;
  }

  h1 {
    margin: 0.67em 0;
    font-size: 2em;
  }

  a {
    background-color: transparent;
  }

  abbr[title] {
    text-decoration: underline dotted;
  }

  code,
  kbd,
  pre,
  samp {
    font-size: 1em;
    font-family: monospace, monospace;
  }

  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    line-height: inherit;
    border: 1px solid currentcolor;
  }

  button {
    overflow: visible;
    text-transform: none;
  }

  [type='button'],
  [type='reset'],
  [type='submit'],
  button {
    padding: 1px 6px;
    appearance: button;
  }

  input {
    overflow: visible;
  }

  input,
  textarea {
    padding: 1px;
  }

  fieldset {
    margin: 0 2px;
    border: 1px solid currentcolor;
  }

  legend {
    display: table;
    max-width: 100%;
    color: inherit;
    white-space: normal;
  }

  progress {
    display: inline-block;
    vertical-align: baseline;
  }

  select {
    text-transform: none;
  }

  textarea {
    overflow: auto;
    vertical-align: top;
  }

  [type='search'] {
    outline-offset: -2px;
    appearance: textfield;
  }

  [type='color'] {
    background: inherit;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  ::input-placeholder {
    color: inherit;
    opacity: 0.5;
  }

  ::-webkit-file-upload-button,
  ::-webkit-search-decoration {
    font: inherit;
    appearance: button;
  }

  ::-moz-focus-inner {
    border: 0;
  }

  :-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    color: inherit;
  }

  dl,
  ol,
  ul {
    margin: 1em 0;
  }

  dl dl,
  dl ol,
  dl ul,
  ol dl,
  ol ol,
  ol ul,
  ul dl,
  ul ol,
  ul ul {
    margin: 0;
  }

  b,
  strong {
    font-weight: bolder;
  }

  audio,
  video {
    display: inline-block;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  img {
    border: 0;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  table {
    text-indent: 0;
    border-color: inherit;
  }

  details {
    display: block;
  }

  dialog {
    position: absolute;
    right: 0;
    left: 0;
    display: block;
    width: fit-content;
    height: fit-content;
    margin: auto;
    padding: 1em;
    color: inherit;
    background-color: inherit;
    border: solid;
  }

  dialog:not([open]) {
    display: none;
  }

  summary {
    display: list-item;
  }

  canvas {
    display: inline-block;
  }

  template {
    display: none;
  }

  [hidden] {
    display: none;
  }
}
