@mixin m-theme-create($variables, $theme: null, $forRoot: true, $prefix: '', $colorScheme: light dark) {
  @if $forRoot == true {
    :root {
      @each $name, $value in $variables {
        #{$prefix + $name}: $value;
      }
    }
  }

  @if $theme {
    .px-theme-#{$theme},
    .#{$theme} {
      @each $name, $value in $variables {
        #{$prefix + $name}: $value;
      }
    }
  } @else {
    @each $name, $value in $variables {
      #{$prefix + $name}: $value;
    }
  }
}
