@use 'sass:map';
@use 'sass:meta';
@use 'sass:list';
@use 'tools';
@use 'theming';

$available-props: (
  'font-weight',
  'font-size',
  'font-family',
  'line-height',
  'letter-spacing',
  'text-transform',
  'text-decoration'
);

@function f-define-typeset($prefix: 'pui', $name, $config: ()) {
  $result: ();

  @each $prop in $available-props {
    $result: map.set($result, $prop, var(--#{$prefix}-typeset-#{$name}-#{$prop}));
  }

  @if $config != () {
    $result: map.deep-merge($result, $config);
  }

  @return $result;
}

@mixin m-define-typeset($prefix: 'pui', $name, $config: ()) {
  @each $prop in $available-props {
    $value: map.get($config, $prop);

    @if $value {
      --#{$prefix}-typeset-#{$name}-#{$prop}: #{map.get($config, $prop)};
    }
  }
}

@mixin m-use-typeset($prefix: 'pui', $typeset, $props: ()) {
  @each $prop in $available-props {
    @if map.has-key($props, $prop) {
      #{$prop}: var(--#{$prefix}-typeset-#{$typeset}-#{$prop}, #{map.get($props, $prop)});
    } @else {
      #{$prop}: var(--#{$prefix}-typeset-#{$typeset}-#{$prop});
    }
  }

  @each $prop, $value in $props {
    @if not list.index($available-props, $prop) {
      #{$prop}: $value;
    }
  }
}

@mixin m-define-typeset-class($prefix: 'pui', $name) {
  .#{$prefix}-typeset-#{$name} {
    @each $prop in $available-props {
      #{$prop}: var(--#{$prefix}-typeset-#{$name}-#{$prop}) !important;
    }
  }
}

@mixin m-define-comp-typeset($prefix: 'pui', $comp-name, $typeset: '') {
  @each $prop in $available-props {
    @if $typeset == '' {
      --#{$prop}: var(--#{$prefix}-comp-#{$comp-name}-typeset-#{$prop});
    } @else {
      --#{$prop}: var(--#{$prefix}-comp-#{$comp-name}-typeset-#{$prop}, var(--#{$prefix}-typeset-#{$typeset}-#{$prop}));
    }
  }

  @each $prop in $available-props {
    #{$prop}: var(--#{$prop});
  }
}

@mixin m-init-typography($prefix: 'pui', $root: ':root', $config: ()) {
  @if meta.type-of($prefix) == list {
    @each $item in $prefix {
      @include m-init-typography($item, $root, $config);
    }
  } @else {
    #{$root} {
      @each $key, $value in $config {
        @include m-define-typeset($prefix, $key, tools.flat-map($value));
      }
    }

    @each $key, $value in $config {
      @include m-define-typeset-class($prefix, $key);
    }
  }
}
