// ==============================================================================
@use '@angular/material' as mat;
@use 'libs/mds/styles' as mds;

// 1. Define the colors for one of the pairs -- either light pair or dark pair.
// ===============================================================================

// Background palette for light themes.
$light-theme-background: (
  status-bar: map_get(mat.$m2-grey-palette, 300),
  app-bar: map_get(mat.$m2-grey-palette, 100),
  background: white,
  hover: rgba(black, 0.04),
  card: white,
  dialog: white,
  disabled-button: rgba(black, 0.08),
  raised-button: white,
  focused-button: $dark-focused,
  selected-button: map_get(mat.$m2-grey-palette, 300),
  selected-disabled-button: map_get(mat.$m2-grey-palette, 400),
  disabled-button-toggle: map_get(mat.$m2-grey-palette, 200),
  unselected-chip: map_get(mat.$m2-grey-palette, 300),
  disabled-list-option: map_get(mat.$m2-grey-palette, 200),
  tooltip: map_get(mat.$m2-grey-palette, 700),
);

// Foreground palette for light themes.
$light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba(black, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  hint-text: $dark-disabled-text,
  secondary-text: $dark-secondary-text,
  icon: rgba(black, 0.54),
  hover-icon: #232325,
  icons: rgba(black, 0.54),
  text: rgba(black, 0.8),
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

// ------------------------------------------------------------------------------

// Background palette for dark themes.
$dark-theme-background: (
  status-bar: black,
  app-bar: map_get(mat.$m2-grey-palette, 900),
  background: #303030,
  hover: rgba(white, 0.04),
  card: map_get(mat.$m2-grey-palette, 800),
  dialog: map_get(mat.$m2-grey-palette, 800),
  disabled-button: rgba(white, 0.12),
  raised-button: map-get(mat.$m2-grey-palette, 800),
  focused-button: $light-focused,
  selected-button: map_get(mat.$m2-grey-palette, 900),
  selected-disabled-button: map_get(mat.$m2-grey-palette, 800),
  disabled-button-toggle: black,
  unselected-chip: map_get(mat.$m2-grey-palette, 700),
  disabled-list-option: black,
  tooltip: map_get(mat.$m2-grey-palette, 700),
);

// Foreground palette for dark themes.
$dark-theme-foreground: (
  base: white,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba(white, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-secondary-text,
  icon: rgba(255, 255, 255, 60%),
  hover-icon: #dedede,
  icons: white,
  text: white,
  slider-min: white,
  slider-off: rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3),
);

// ==============================================================================
// 2. Define the primary, accent, warning colors; logo version
// ==============================================================================

$primary: mat.m2-define-palette(mat.$m2-blue-gray-palette, 900);
$accent: mat.m2-define-palette(mat.$m2-blue-gray-palette, 'A200', 'A100', 'A400');
$warn: mat.m2-define-palette(mat.$m2-red-palette);
$logo: dark; // or light

// ==============================================================================
// 3. Uncomment one of the variables
// ==============================================================================

$colors: color-light-theme($primary, $accent, $warn, $light-theme-foreground, $light-theme-background);

//$colors: color-dark-theme($primary, $accent, $warn, $dark-theme-foreground, $dark-theme-background);

// ==============================================================================
// 4. Rename the wrapper class
// ==============================================================================

.colors-light {
  @include color-scheme($colors, $logo, #{&});
}

@include mds.legacy-px-m-theme-create(
  (
    --px-ca-body-background-color-rgb: (
      255,
      255,
      255,
    ),
    --px-ca-body-background-color: rgb(255, 255, 255),
    --px-ca-cover-background-color: #fff,
    --px-ca-body-color: rgba(0, 0, 0, 0.8),
    --px-ca-body-color-rgb: (
      0,
      0,
      0,
    ),
    --px-ca-body-color-rgb-alpha: 0.8,
    --px-ca-color-primary: #263238,
    --px-ca-button-disabled-bg: #e5e5e5ff,
    --px-ca-button-disabled-text: #afafafff,
  ),
  colors-light-bg,
  false
);
@include mds.m-init-theme(ca-light, '.colors-light-bg');
