@use 'sass:string';
@use 'sass:meta';
@use 'sass:map';
@use './string-ext';

@function create-name($name, $prefix: null) {
  $string: if($prefix == null, $name, string.unquote('#{$prefix}-#{$name}'));
  $string: if(string-ext.has-prefix($string, '--'), $string, string.unquote('--#{$string}'));

  @return $string;
}

@mixin m-set-custom-prop($name, $value, $prefix: null) {
  #{create-name($name, $prefix)}: #{$value};
}
