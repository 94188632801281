@font-face {
  font-family: 'icons';
  src: url('./icons.eot?b8192d48d7f37389408a511da111c406?#iefix') format('embedded-opentype'),
    url('./icons.woff2?b8192d48d7f37389408a511da111c406') format('woff2'),
    url('./icons.woff?b8192d48d7f37389408a511da111c406') format('woff'),
    url('./icons.ttf?b8192d48d7f37389408a511da111c406') format('truetype'),
    url('./icons.svg?b8192d48d7f37389408a511da111c406#icons') format('svg');
  font-display: block; /* the only line added to default template */
}

i[class^='icon-']::before,
i[class*=' icon-']::before,
span[class^='icon-']::before,
span[class*=' icon-']::before {
  font-weight: normal !important;
  font-family: 'icons', serif !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-02-download::before {
  content: '\f101';
}

.icon-09-more-bins::before {
  content: '\f102';
}

.icon-apple-safari::before {
  content: '\f103';
}

.icon-arrow-down-90-degrees::before {
  content: '\f104';
}

.icon-arrow-down::before {
  content: '\f105';
}

.icon-arrow-left::before {
  content: '\f106';
}

.icon-arrow-right::before {
  content: '\f107';
}

.icon-download::before {
  content: '\f108';
}

.icon-google-chrome::before {
  content: '\f109';
}

.icon-microsoft-edge::before {
  content: '\f10a';
}

.icon-mozilla-firefox::before {
  content: '\f10b';
}

.icon-siv-back::before {
  content: '\f10c';
}
